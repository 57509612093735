define("discourse/plugins/docker_manager/discourse/components/docker-manager/console", ["exports", "@glimmer/component", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "discourse/lib/decorators", "@ember/component", "@ember/template-factory"], function (_exports, _component, _didInsert, _didUpdate, _decorators, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Console extends _component.default {
    scrollToBottom(element1) {
      if (this.args.followOutput) {
        element1.scrollTop = element1.scrollHeight;
      }
    }
    static #_ = (() => dt7948.n(this.prototype, "scrollToBottom", [_decorators.bind]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div
          class="console-logs"
          {{didInsert this.scrollToBottom}}
          {{didUpdate this.scrollToBottom @output}}
        >
          {{~@output~}}
        </div>
      
    */
    {
      "id": "rUSYTm9D",
      "block": "[[[1,\"\\n    \"],[11,0],[24,0,\"console-logs\"],[4,[32,0],[[30,0,[\"scrollToBottom\"]]],null],[4,[32,1],[[30,0,[\"scrollToBottom\"]],[30,1]],null],[12],[1,[30,1]],[13],[1,\"\\n  \"]],[\"@output\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/docker_manager/discourse/components/docker-manager/console.js",
      "scope": () => [_didInsert.default, _didUpdate.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = Console;
});